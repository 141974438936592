import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

window.addEventListener('load', ()=> {
    animation();
})

function animation() {
    const page = document.querySelector('.page-wrapper');
    page.removeAttribute('style');

    const fadeUp = document.querySelectorAll('.fade-up');
    gsap.set(fadeUp, {
        opacity: 0,
        y: '200px',
    });
    fadeUp.forEach(element => {
        const delay = element.dataset.delay ? element.dataset.delay : 0;
        const duration = element.dataset.duration ? element.dataset.duration : 1;

        gsap.to(element, {
            scrollTrigger: element,
            opacity: 1,
            y: 0,
            delay: delay,
            duration: duration,
        });
    });

    const fadeDown = document.querySelectorAll('.fade-down');
    gsap.set(fadeDown, {
        opacity: 0,
        y: '-200px',
    });
    fadeDown.forEach(element => {
        const delay = element.dataset.delay ? element.dataset.delay : 0;
        const duration = element.dataset.duration ? element.dataset.duration : 1;
        gsap.to(element, {
            scrollTrigger: element,
            opacity: 1,
            y: 0,
            delay: delay,
            duration: duration,
        });            
    });

    const fadeOut = document.querySelectorAll('.fade-out');
    gsap.set(fadeOut, {
        opacity: 0,
    });
    fadeOut.forEach(element => {
        const delay = element.dataset.delay ? element.dataset.delay : 0.1;
        const duration = element.dataset.duration ? element.dataset.duration : 1;

        gsap.to(element, {
            scrollTrigger: element,
            opacity: 1,
            delay: delay,
            duration: duration,
        });    
    });

    const moveUp = document.querySelectorAll('.move-up');

    moveUp.forEach(element => {        
        const delay = element.dataset.delay ? element.dataset.delay : 0.2;
        const duration = element.dataset.duration ? element.dataset.duration : 1;
        const top = element.dataset.top ? element.dataset.top : '50px';
        const topEnd = element.dataset.topend ? element.dataset.topend : '0px';

        gsap.set(element, {
            top: top,
        });

        gsap.to(element, {
            scrollTrigger: element,
            top: topEnd,
            delay: delay,
            duration: duration,
        });
    });

    const moveDown = document.querySelectorAll('.move-down');

    moveDown.forEach(element => {        
        const delay = element.dataset.delay ? element.dataset.delay : 0.2;
        const duration = element.dataset.duration ? element.dataset.duration : 1;
        const bottom = element.dataset.top ? element.dataset.top : '50px';
        const bottomEnd = element.dataset.topend ? element.dataset.topend : '0px';

        gsap.set(element, {
            bottom: bottom,
        });

        gsap.to(element, {
            scrollTrigger: element,
            bottom: bottomEnd,
            delay: delay,
            duration: duration,
        });
    });

    const zoomOut = document.querySelectorAll('.zoom-out');
    
    zoomOut.forEach(element => {
        gsap.set(element, {
            scale: 1.1,
        });

        gsap.to(element, {
            scrollTrigger: element,
            scale: 1,
            duration: 1,
        })
    });
}
